import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import tw, { styled } from "twin.macro"
import { Link } from "gatsby"
import { AiOutlineInstagram } from "react-icons/ai"
import { FaFacebookF } from "react-icons/fa"
import { SiTiktok } from "react-icons/si"
import { ImPinterest2 } from "react-icons/im"

const Wrap = styled.div`
  width: 100%;
  display: grid;
  //grid-template-columns: repeat(6, 1fr);
  //grid-template-rows: 1fr 1fr;
  padding-bottom: 15px;
  margin-top: 50px;
  .list {
    width: 250px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 25px;
    padding-left: 0;
    a {
      display: inline;
      padding-right: 15px;
    }
  }
  .sublist {
    padding-left: 0;
    width: 250px;
    margin: 0 auto;
    li {
      list-style-type: none;
      text-align: center;
    }
    .privacy {
      padding-bottom: 15px;
    }
  }
`

const ImgWrap = styled.div`
  margin: 10px auto;
`

// try this once get acccess token https://www.gatsbyjs.com/plugins/gatsby-source-instagram-all/?=instagram

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      sanityHomePage {
        footerLogo {
          asset {
            fixed(width: 50) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Wrap>
        {/* {data.allInstaNode.edges.map(({ node: post }) => {
          return (
            <a key={post.id} href={`https://instagram.com/p/${post.id}`}>
              <Img
                fluid={post.localFile && post.localFile.childImageSharp.fluid}
              />
            </a>
          )
        })} */}
        <ul className="list">
          <p>Follow Us</p>
          <a href="https://www.instagram.com/studio_conscious">
            <AiOutlineInstagram />
          </a>
          <a href="https://www.facebook.com/studioconsciousco">
            <FaFacebookF />
          </a>
          <a href="https://www.tiktok.com/@studioconscious">
            <SiTiktok />
          </a>
          <a href="https://www.pinterest.ca/studioconscious">
            <ImPinterest2 />
          </a>
        </ul>

        <ul className="sublist">
          <li className="privacy">
            <Link to="/privacy">Privacy Policy</Link>
          </li>
          <li>&#169; Studio Conscious</li>
        </ul>
        <ImgWrap>
          <Img
            fixed={
              data.sanityHomePage.footerLogo &&
              data.sanityHomePage.footerLogo.asset.fixed
            }
          />
        </ImgWrap>
      </Wrap>
    </>
  )
}

export default Header
