import tw, { styled } from "twin.macro"

export const HeaderWrap = styled.h2`
  ${tw`text-center mt-20`};
  transform: translateY(10px);
`

export const SubHeader = styled.span`
  ${tw`block italic text-center lg:text-xl`};
  font-weight: 300;
  @media (min-width: 600px) {
    padding-bottom: 75px;
  }
`

export const Header = styled.span`
  color: ${({ color }) => color};
  ${tw`text-3xl lg:text-6xl lowercase text-center`};
  &:first-child {
    font-family: "Playfair Display";
    padding-right: 10px;
    @media (min-width: 600px) {
      padding-right: 20px;
    }
    font-weight: 700;
  }
  &:last-child {
    font-family: "Playfair Display";
    font-weight: 700;
    ${tw`text-3xl lg:text-6xl lowercase text-center`};
    @media (max-width: 1024px) {
      font-size: 30px;
    }
  }
  &.spin {
    font-family: "Playfair Display";
  }
`

export const ProductWrap = styled.section`
  ${tw`grid grid-cols-1 col-gap-16 sm:grid-cols-3 mt-8 mb-8`}
`

export const ProductCard = styled.div`
  h3 {
    ${tw`text-xl`}
    @media(min-width:600px) {
      height: 50px;
    }
    @media (max-width: 600px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    span {
      ${tw`italic`}
    }
  }
`
