import React, { useState } from "react"
import Img from "gatsby-image"
import tw, { styled } from "twin.macro"
import Layout from "../components/layout"
import { Link } from "gatsby"
import {
  HeaderWrap,
  SubHeader,
  Header,
  ProductWrap,
  ProductCard,
} from "../components/productListStyles"
import Footer from "../components/Footer"
// import { FiShoppingCart } from "react-icons/fi"
// import { CartItems } from "../components/cart"

const CartButton = styled.div`
  position: fixed;
  top: 50px;
  right: 15px;
  @media (min-width: 1024px) {
    top: 15px;
    right: 30px;
  }
  z-index: 6000;
  button {
    &:hover {
      cursor: pointer;
    }
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
  }
`

const Curations = ({ data, location }) => {
  const [close, setClose] = useState(false)

  const toggleCart = () => {
    setClose(!close)
  }
  const showCart = () => {
    setClose(true)
  }

  return (
    <Layout location={location}>
      {/* <CartItems close={close} location={location} />
      <CartButton>
        <button onClick={toggleCart}>
          <FiShoppingCart size={25} />
        </button>
      </CartButton> */}
      <HeaderWrap>
        {data.sanityBlogPage.header.map((head, i) => {
          return (
            <Header key={i} color={head.myColor.hex}>
              {head.title}
            </Header>
          )
        })}
      </HeaderWrap>
      <SubHeader>{data.sanityBlogPage.subHeader}</SubHeader>
      <ProductWrap>
        {data.allSanityBlog.edges.map(({ node: blog }, i) => {
          return (
            <Link
              key={i}
              to={`/blog/${blog.header.replace(/\s/g, "-").toLowerCase()}`}
            >
              <ProductCard>
                <h3>{blog.header}</h3>
                <Img
                  fluid={blog.featureImage && blog.featureImage.asset.fluid}
                />
              </ProductCard>
            </Link>
          )
        })}
      </ProductWrap>
      <Footer />
    </Layout>
  )
}

export default Curations

export const query = graphql`
  query BlogQuery {
    allSanityBlog {
      edges {
        node {
          _id
          header
          featureImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    sanityBlogPage {
      header {
        title
        myColor {
          hex
        }
      }
      subHeader
    }
  }
`
